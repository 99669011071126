export const ADMIN_AUTH_KEY = 'arema-auth-admin';
export const EXTERNAL_AUTH_KEY = 'arema-auth-external';

export const ADMIN_AUTH_EVENT = 'arema-auth-admin-login';
export const EXTERNAL_AUTH_EVENT = 'arema-auth-external-login';

export enum LoginType{
	ADMIN = 1,
	EXTERNAL = 2,
}

export enum AdminLoginType{
	ADMIN = 1,
	PDV_APP = 20,
	SCANNER_APP = 21,
	GENERIC = 255,
}

export interface AuthTokens{
	access: string,
	refresh: string,
	ttl: number,
	expires: number,
	refresh_ttl: number,
	refresh_expires: number,
}

export interface AdminLogin{
	user: {
		admin_id: number,
		username: string,
		email: string,
		first_name: string,
		last_name: string,
		superadmin: boolean,
		change_password: boolean,
	},
	tokens: AuthTokens
}

export interface ExternalLogin{
	user: {
		external_id: string,
		email: string,
		first_name: string,
		last_name: string,
	},
	tokens: AuthTokens
}
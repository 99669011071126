import React, { useEffect, useRef, useState } from 'react';
import { AdminLoginType, AuthTokens, LoginType } from '../AuthClasses';
import { Button, Groupper, Header, Input, Message } from 'react-frontier';
import { Checkbox } from 'semantic-ui-react';
import { useValidator } from '@arema/components/Hooks';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth } from '../AuthHooks';
import API, { CAPTCHA_ACTIVE, CDN_ROOT, RECAPTCHA_KEY } from '../AuthAPI';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';

import '../scss/login.scss';

interface LoginProps{
	type: LoginType
}

enum LoginMode{
	LOGIN = 1,
	FORGOT = 2,
	TOTP = 3,
}

interface LoginForm{
	email: string,
	forgot_email: string,
	password: string,
	totp: string,
	captcha: string,
	remember: boolean,
}

var Login = (props: LoginProps)=>{
	var recaptchaRef = useRef<ReCAPTCHA>(null);
	var [query] = useSearchParams();
	var [mode, setMode] = useState<LoginMode>(LoginMode.LOGIN);
	var [sending, setSending] = useState<boolean>(false);
	var [loggedIn, setLoggedIn] = useState<boolean>(false);
	var [loginError, setLoginError] = useState<string>(null);
	var [forgotSent, setForgotSent] = useState<boolean>(false);
	var { admin, admin_saved, external, external_saved, clearSavedAdmin, loginSavedAdmin, clearSavedExternal, loginSavedExternal } = useAuth();
 	var { data, errors, validate, onDataChange, prompts, setPrompts } = useValidator<LoginForm>({
		email: '',
		forgot_email: '',
		password: '',
		totp: '',
		captcha: null,
		remember: false,
	}, {
		email: [{
			rule: props.type===LoginType.ADMIN ? 'minLength' : 'email',
			params: [5],
			prompt: `El ${props.type===LoginType.ADMIN ? 'usuario' : 'correo electrónico'} no es válido`
		}],
	});

	var continue_url = decodeURIComponent(query.get('continue') || '');
	var params_valid = !!continue_url && continue_url.length>=10;

	var getButtonText = ()=>{
		switch(mode){
			case LoginMode.LOGIN: return 'Iniciar sesión'
			default: return 'Continuar';
		}
	}

	var getLoginName = ()=>{
		switch(props.type){
			case LoginType.ADMIN: return 'Administrador';
			case LoginType.EXTERNAL: return 'Empresarios';
			default: return null;
		}
	}

	var getContinueUrl = (tokens: AuthTokens)=>{
		if(!continue_url || !tokens) return null;
		continue_url = continue_url.replace(/\/$/gi, '');
		var params : any = {
			at: tokens.access,
			e: tokens.expires,
			lt: props.type,
		}
		var redirect_url = query.get('redirect');
		if(redirect_url && redirect_url.length>=10){
			params.r = redirect_url;
		}
		return `${continue_url}?${Object.keys(params).map(a=>`${a}=${params[a]}`).join('&')}`
	}

	var refreshAdmin = ()=>{
		if(!admin_saved) return;
		setSending(true);
		loginSavedAdmin().then(res=>{
			if(res.error) return setLoginError(res.message);
			if(!res.data.tokens?.access){
				return setLoginError(`Hubo un error inesperado iniciando sesión (LCL-SVD${props.type || 'X'}-2)`);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setLoginError(`Hubo un error inesperado haciendo login. (LCL-SVD${props.type || 'X'}-1)`);
		}).finally(()=>{
			setSending(false);
		})
	}

	var refreshExternal = ()=>{
		if(!external_saved) return;
		setSending(true);
		loginSavedExternal().then(res=>{
			if(res.error) return setLoginError(res.message);
			if(!res.data.tokens?.access){
				return setLoginError(`Hubo un error inesperado iniciando sesión (LCL-SVD${props.type || 'X'}-2)`);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setLoginError(`Hubo un error inesperado haciendo login. (LCL-SVD${props.type || 'X'}-1)`);
		}).finally(()=>{
			setSending(false);
		})
	}

	var refreshAuth = ()=>{
		if(props.type===LoginType.ADMIN){
			return refreshAdmin();
		}else if(props.type===LoginType.EXTERNAL){
			return refreshExternal();
		}
	}

	var submitAdminLogin = (captcha: string)=>{
		var login_type = parseInt(query.get('lt'));
		if(Number.isNaN(login_type)){
			login_type = AdminLoginType.GENERIC
		}
		
		setSending(true);
		API.loginAdmin(data.email, data.password, login_type, captcha, data.remember).then(res=>{
			if(res.error) return setPrompts([res.message]);
			if(!res.data.tokens?.access){
				return setPrompts(['Hubo un error inesperado iniciando sesión (LCL-ADMLG-2)']);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setPrompts(['Hubo un error inesperado iniciando sesión (LCL-ADMLG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submitExternalLogin = (captcha: string)=>{
		setSending(true);
		API.loginExternal(data.email, data.password, captcha, data.remember).then(res=>{
			if(res.error) return setPrompts([res.message]);
			if(!res.data.tokens?.access){
				return setPrompts(['Hubo un error inesperado iniciando sesión (LCL-EXLG-2)']);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setPrompts(['Hubo un error inesperado iniciando sesión (LCL-EXLG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submitAdminForgot = (captcha: string)=>{
		var { valid } = validate({ captcha }, {
			email: [{
				rule: 'email', prompt: 'El correo electrónico no es válido!!!',
			}]
		});
		if(!valid) return;
		setPrompts(null);

		setSending(true);
		API.sendAdminForgot(data.email, captcha).then(res=>{
			if(res.error) return setPrompts([res.message]);
			
		}).catch(err=>{
			setPrompts(['Hubo un error haciendo la solicitud de contraseña. (LCL-ADMFG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submitExternalForgot = (captcha: string)=>{
		setSending(true);
		API.sendExternalForgot(data.email, captcha).then(res=>{
			if(res.error) return setPrompts([res.message]);
		}).catch(err=>{
			setPrompts(['Hubo un error haciendo la solicitud de contraseña. (LCL-EXTFG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var clearSaved = ()=>{
		if(props.type===LoginType.ADMIN){
			return clearSavedAdmin();
		}else if(props.type===LoginType.EXTERNAL){
			return clearSavedExternal();
		}
	}

	var changeLoginType = ()=>{
		window.location.href = `${window.location.origin}/${props.type===LoginType.ADMIN ? 'external' : 'admin'}${window.location.search}`;
	}

	var submit = async ()=>{
		var { valid } = validate(null, {
			password: mode===LoginMode.LOGIN ? [{
				rule: 'minLength', params: [5], prompt: 'La contraseña debe de ser de mínimo 5 caracteres'
			}] : []
		});
		if(!valid) return;

		var cph : string = null;
		if(CAPTCHA_ACTIVE){
			if(!data.captcha){
				try{
					setSending(true);
					recaptchaRef.current.reset();
					var cph = await recaptchaRef.current.executeAsync()
					if(!cph) return setPrompts(['Hubo un error realizando el captcha (LCL-ADMSB-1)']);
					onDataChange('captcha')(cph);
				}catch(e){
					return setPrompts(['Hubo un error realizando el captcha (LCL-ADMSB-2)']);
				}finally{
					setSending(false);
				}
			}else{
				cph = data.captcha;
			}
		}
		
		switch(mode){
			case LoginMode.LOGIN: 
				if(props.type===LoginType.ADMIN){
					return submitAdminLogin(cph);
				}else if(props.type===LoginType.EXTERNAL){
					return submitExternalLogin(cph);
				}
			break;
			case LoginMode.FORGOT:
				if(props.type===LoginType.ADMIN){
					return submitAdminForgot(cph);
				}else if(props.type===LoginType.EXTERNAL){
					return submitExternalForgot(cph);
				}
			break;
		}
	}

	var logged_in = loggedIn && (props.type===LoginType.ADMIN ? !!admin : (props.type===LoginType.EXTERNAL ? !!external : null));
	var login_saved = props.type===LoginType.ADMIN ? (!!admin && admin_saved) : (props.type===LoginType.EXTERNAL ? (!!external && external_saved) : false);
	var login_data : { user: { first_name: string, last_name: string }, tokens: AuthTokens } = props.type===LoginType.ADMIN ? admin : (props.type===LoginType.EXTERNAL ? external : null);
	
	return <div className={classNames("ar login-screen", {
		admin: props.type===LoginType.ADMIN
	})}>
		<Groupper defaultStyle={false} actions={params_valid && !logged_in && !login_saved && !forgotSent ? <>
			{(mode===LoginMode.TOTP || mode===LoginMode.FORGOT) && (
				<Button basic text={'Regresar'} onClick={()=>setMode(LoginMode.LOGIN)} />
			)}
			<Button color='black' text={getButtonText()} onClick={submit} loading={sending} />	
		</> : null}>
			<div className="head logo">
				<img src={`${CDN_ROOT}/assets/logo/Logo${props.type===LoginType.ADMIN ? 'W' : ''}H_Large.webp`} alt="AREMA" />
			</div>
			{logged_in ? (
				<div className="contents full">
					<Header
						iconName='check circle' 
						iconStyle={{ color: '#1a9461', marginBottom: 10 }} 
						text={`¡Hola, ${(login_data?.user.first_name || 'Usuario')}!`} 
						subtext='Te redireccionaremos pronto a tu destino' 
						subheaderStyle={{ marginTop: 5 }}
						actions={(
							<Button text='Continuar' size='tiny' style={{ padding: '2px 10px', minWidth: 100 }} basic color='green' as={Link} to={getContinueUrl(login_data?.tokens)} />
						)}
					/>
				</div>
			) : params_valid ? <>
				<Groupper.Divider centered top>
					<Header centered text={getLoginName()} subtext={mode===LoginMode.FORGOT ? 'Olvidé mi contraseña' : null} />
				</Groupper.Divider>
				{(login_saved && login_data) ? (
					<div className="contents full semi">
						{loginError ? <>
							<Header iconName='exclamation triangle' iconStyle={{ fontSize: 70, marginBottom: 15 }} text='Error' subtext={loginError} />
							<Button text='Regresar' style={{ marginTop: 15, minWidth: 200 }} onClick={()=>setLoginError(null)} loading={false} />
						</> : <>
							<Header
								iconName='user circle' 
								iconStyle={{ marginBottom: 10 }} 
								text={`${login_data.user.first_name} ${login_data.user.last_name}`} 
								subtext='Guardamos tu sesión'
							/>
							<Button text='Iniciar sesión' style={{ marginTop: 15, minWidth: 200 }} color='orange' onClick={refreshAuth} loading={sending} />
							<Button text='Cerrar sesión' size='tiny' style={{ marginTop: 5, minWidth: 120 }} basic color='black' onClick={clearSaved} disabled={sending} />
						</>}
					</div>
				)  : forgotSent ? (
					<div className="contents semi full">
						<Header 
							iconName='mail' 
							iconStyle={{ fontSize: 70, marginBottom: 10 }} 
							text='Enlace enviado' 
							subtext={`Si existe una cuenta de AREMA${props.type===LoginType.ADMIN ? ' Administrador' : ''} con este correo electrónico, te enviamos un correo con instrucciones para restablecer tu contraseña.`} 
							subheaderStyle={{ lineHeight: '16px' }} 
						/>
						<Button text='Iniciar sesión' color='black' style={{ marginTop: 20, minWidth: 200 }} onClick={()=>{
							setForgotSent(null);
							setMode(LoginMode.LOGIN);
						}} loading={false} />
					</div>
				) : (
					<div className="contents">
						{mode===LoginMode.LOGIN ? <>
							<Input label={props.type===LoginType.ADMIN ? 'Usuario' : 'Correo electrónico'} error={errors.email} value={data.email} onChange={onDataChange('email')} submitOnEnter onSubmit={submit} />
							<Input label='Contraseña' style={{ marginBottom: 0 }} error={errors.password} value={data.password} onChange={onDataChange('password')} inputType='password' submitOnEnter onSubmit={submit} />
							<Checkbox toggle label={'Recordar mi sesión'} style={{ marginTop: 15 }} checked={data.remember} onChange={onDataChange('remember', true)} />
							<div className="forgot">
								<Button basic size='tiny' text='Olvidé mi contraseña' color='black' onClick={()=>setMode(LoginMode.FORGOT)} />
							</div>
						</> : mode===LoginMode.FORGOT ? <>
							<Message centered type='info' text={`Ingresa el correo electrónico de tu cuenta de AREMA ${props.type===LoginType.ADMIN ? 'Administrador' : ''}`} style={{ marginBottom: 15 }} />
							<Input label={'Correo electrónico'} error={errors.email} value={data.email} onChange={onDataChange('email')} />
						</> : null}
						<Message type='error' list={prompts} style={{ marginTop: 5 }} />
					</div>
				)}
			</> : (
				<div className="contents full">
					<Header iconName='exclamation triangle' iconStyle={{ fontSize: 70, marginBottom: 15 }} text='Autenticación inválida' subtext='El desarrollo ha configurado mal la autenticación de usuario' />
				</div>
			)}
			{CAPTCHA_ACTIVE && (
				<ReCAPTCHA 
					hl='es-419'
					size='invisible'
					sitekey={RECAPTCHA_KEY}
					ref={recaptchaRef}
					onChange={onDataChange('captcha')}
					onExpired={()=>onDataChange('captcha')(null)}
				/>
			)}
		</Groupper>
		{query.get('ac')=='1' && (
			<Button basic text={`Acceso ${props.type===LoginType.ADMIN ? 'empresario' : 'administrador'}`} iconName={props.type===LoginType.ADMIN ? 'user tie' : 'user cog'} color='white' style={{ marginTop: 10 }} onClick={changeLoginType} />
		)}
	</div>
}

export default Login;